.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.blackout
{
  background-color: rgba(0, 0, 0, 0.3);
}

.fullscreen
{
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
}

.absolute
{
  position: absolute;
}

.popup
{
  z-index: 99;
}

.relative
{
  position: relative;
}

.flex
{
  display: flex;
}

.flex-col
{
  display: flex;
  flex-direction: column;
}

.flex-center
{
  justify-content: center;
}

.animation-box
{
  /* border: 0.2vw solid red; */
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  margin: auto;
  width: 350px;
  height: 350px;
  position: relative;
}

.loading-dot
{
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background-color: #61dafb;
  position: absolute;
  animation: circle 1.5s linear infinite;
}

@keyframes circle {
  0% { top: 20%; left: 48%; }
  7% { top: 20.5%; left: 50%; }
  14% { top: 22%; left: 55%; }
  21% { top: 25%; left: 62%; }
  28% { top: 35%; left: 70%; }
  35% { top: 49%; left: 72%; }
  42% { top: 65%; left: 69%; }
  49% { top: 74%; left: 48%; }
  56% { top: 65%; left: 28%; }
  63% { top: 45%; left: 23%; }
  70% { top: 30%; left: 29%; }
  77% { top: 23%; left: 36%; }
  84% { top: 21%; left: 40%; }
  91% { top: 20.5%; left: 42%; }
  100% { top: 20%; left: 48%; }
}