.calendar {
  height: 600px;
  width: 800px;
}

main {
  display: flex;
  margin-top: 10px;
  justify-content: space-evenly;

}

.toolbar {
  margin-top: 70px;
  background-color: #d0ebfc;
  width: 300px;
  border-radius: 15px;
  margin-bottom: 12%;
  /* margin-right: 8%; */
  font-family: monospace;
  font-size: medium;
  align-content: center;
  padding: 33px 10px 60px 10px;
}

.nav {

  font-family: monospace;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: linear-gradient(135deg, #cbedef, #4a90e2);
  color: white;
  font-size: 24px;
  font-weight: bold;
  /* box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2); */
  transition: all 0.3s ease-in-out;
}



.form {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 500px;
  background: linear-gradient(150deg, #d0ebfc, #84ccf9, #52ace4);
  border-radius: 30px;
  border: 2px solid #3697d3;
  top: 10%;
  left: 5%;
}

.form input {

  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  border-radius: 5px;
  border: 3px solid #256f9c;
  padding: 5px 10px;

}

.form input:focus,
#generate:focus {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}

.form button,
#generate {
  background-color: #3688D8;
  color: white;
  font-size: 16px;
  width: fit-content;
  align-self: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.form button:hover,
#generate:hover {
  background-color: #0056b3;
}

.tasklabel
{
  align-self: flex-start;
  margin-left: 5%;
  font-size: large;
}

.addTaskButton
{
  border-radius: 100%;
  border: 0px solid black;
  background-color: rgba(0, 108, 223, 0.3);
  color: rgb(0, 108, 223);
  font-size: 50px;
  width: 65px;
  transition: background-color 0.4s ease;
}
.addTaskButton:hover
{
  background-color: #207ee2;
  color: #e3f1ff;
}

button.cancelButton
{
  align-self: flex-end;
  background-color: #e0453d;
  border-radius: 100%;
  padding: 8px 13px;
  margin-right: 5%;
  margin-top: 2%;
}
button.cancelButton:hover
{
  background-color: #bb2e26;
}

.header
{
  padding: 3% 0% 1% 0%;
  font-size: 25px;
}

#taskDisplay
{
  background-color: rgb(251, 213, 144);
  border-radius: 15px;
  min-height: 150px;
  max-height: 350px;
  align-items: center;
  padding-bottom: 5%;
  overflow-y: auto;
}

#default-task-box
{
  background-color: rgba(0, 0, 0, 0);
}
.task-box
{
  background-color: rgba(203, 136, 10, 0.342);
  border-radius: 5px;
  width: 85%;
  margin: 1% 0%;
}
.task-box > *:first-child
{
  margin: 10px 0px 5px 0px;
  font-size: 1.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
  margin: 10px auto 5px auto;
}
.task-info
{
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 5%;
}